import { motion } from "framer-motion";
import React from "react";
import { useLocale } from "./LocaleProvider";
import NavLink from "./NavLink";

type FaqLinkProps = {
	href: string;
	title: string;
};

const FaqLink: React.FC<FaqLinkProps> = ({ href, title }) => {
	return (
		<a href={href} className="mb-2 block text-gray-600 hover:text-blue-400">
			{title}
		</a>
	);
};

const TelLink: React.FC<Record<"title" | "value", string>> = ({
	title,
	value,
}) => {
	return (
		<a
			href={`tel:${value}`}
			className="group mb-2 flex items-end justify-between text-gray-600"
		>
			<span className="font-semibold">{title}</span>
			<span className="ml-2 font-sans text-xs tracking-wide group-hover:text-blue-500">
				{value}
			</span>
		</a>
	);
};

const NavHelp: React.FC = () => {
	const l = useLocale().keys;
	return (
		<motion.div className="flex-column flex bg-white">
			<div className="flex flex-col bg-gradient-to-b from-white via-gray-50 to-white p-4">
				<h4 className="mb-2 font-semibold">{l.sectionFaqTitle}</h4>
				<p className="mb-4 w-60 text-gray-400">
					{l.sectionFaqDescription}
				</p>
				<div className="mb-4">
					<FaqLink title={l.linkFaq1Title} href={l.linkFaq1Url} />
					<FaqLink title={l.linkFaq2Title} href={l.linkFaq2Url} />
					<FaqLink title={l.linkFaq3Title} href={l.linkFaq3Url} />
				</div>
				<div className="mb-4">
					<NavLink
						icon="dotsCircleHorizontal"
						title={l.linkFaqMoreTitle}
						href={l.linkFaqMoreUrl}
					/>
				</div>
				<div className="mt-auto">
					<NavLink
						icon="documentSearch"
						title={l.linkFaqGlossaryTitle}
						href={l.linkFaqGlossaryUrl}
					/>
				</div>
			</div>

			<div className="my-6 border-l" />

			<div className="flex w-60 flex-1 flex-col p-4">
				<h4 className="mb-2 font-semibold">{l.sectionFindUsTitle}</h4>

				<a
					href="#"
					className="mb-4 block w-40 text-gray-500 hover:text-blue-500"
				>
					{l.sectionFindUsDescription}
				</a>
				<div className="mb-4">
					<TelLink
						title={l.labelTelTitle}
						value="+44 (0)20 3326 4444"
					/>
					<TelLink
						title={l.labelFaxTitle}
						value="+44 (0)20 7113 2241"
					/>
					<TelLink
						title={l.labelEmergencyTitle}
						value="08442 488 077"
					/>
				</div>
				<div className="mt-auto">
					<NavLink
						icon="chat"
						title={l.linkChatTitle}
						href={l.linkChatUrl}
					/>
				</div>
			</div>
		</motion.div>
	);
};

export default NavHelp;
